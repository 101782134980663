import React, { Component } from 'react';
import '../components/AboutMe.css';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';

const red = {
  border : "1px solid red"
}

const blue = {
  border : "1px solid blue "
}

class AboutMe extends Component {
  render() {
    return (
        <div className="mod-2 container">
          <div className="row">
            <img className="me" src="hemant2.jpg" />
            <p className="col-md-3 description">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I grew up living in Toronto, Ontario.
                I'm a software developer who likes working with Ruby on Rails,
                ReactJS, and Swift. I have experience in iOS mobile development with Swift where I worked on a mobile game for iOS.
                I'm currently looking for a full stack role within a small to medium sized company.
                <br/>
                <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I've worked on several projects in web development and mobile development.
                More recently I've been working with ReactJS front-end using a Rails API as a back-end. I have two years of Ruby on Rails experience,
                and about a year of mobile development experience with Swift.

            </p>
          </div>
        </div>
    )
  }
}

export default AboutMe;
