import React, { Component } from 'react';
import '../components/Projects.css';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import Slider from 'react-slick'

class Photography extends Component {
  render() {
    return(
      <div className="mod-3 container">
        <div className="row">
          <img src="nepal.jpg"/>
          <img src="eagles.jpg"/>
        </div>
        <div className="row">
          <img src="nepal4.jpg"/>
          <img src="bee.jpg"/>
        </div>
        <div className="row">
          <img src="tajmahal.jpg"/>
          <img src="chennai.jpg"/>
        </div>
      </div>
    )
  }
}

export default Photography;
