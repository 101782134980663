import React, { Component } from 'react';
import '../components/Nav.css';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';


class Nav extends Component {
  render() {
    return (
      <nav className="navbar">
        <ul className="nav">
          <li className="brand">
            <Link to="/aboutme"><img className="logo" src="logo.png"/></Link>
          </li>
          <li className="nav-item">
            <Link to="projects">Projects</Link>
          </li>
          <li className="nav-item">
            <Link to="/photography">Photography</Link>
          </li>
          <li className="nav-item">
            <Link to="/contact">Contact Me</Link>
          </li>
        </ul>
      </nav>
    )
  }
}

export default Nav;
