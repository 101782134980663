import React, { Component } from 'react';
import '../components/Projects.css';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import Project1 from "../components/Project1.js"
import Project2 from "../components/Project2.js"
import Project3 from "../components/Project3.js"

class Projects extends Component {
  render() {
    return (
      <div>
        <div className="container mod-3">
          <Project1 />
        </div>
        <div className="container mod-3">
          <Project2 />
        </div>
        <div className="container mod-3">
          <Project3 />
        </div>
      </div>
    )
  }
}

export default Projects;
