import React, { Component } from 'react';
import '../components/Projects.css';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';

const icons = {
  height:'50px',
  width: '50px',
  display:'inline-block',
  position: 'relative'
}

class Project1 extends Component {
  render() {
    return (
        <div className="container">
          <div className="card">
              <a href="http://rentsixmile.com" target="_blank"> <h2> RentSixMile.com</h2> </a>
              <img src="sml1.png" alt="rentsixmile1"/>
              <img src="sml4.png" alt="rentsixmile2"/>
              <img src="sml3.png" alt="rentsixmile3"/>
              <img src="sml2.png" alt="rentsixmile4"/>
              <div className="description col-md-6 row">
                <p>I created a website using Ruby on Rails that allows cottage goers to rent a cottage.
                Created login using Devise, variable pricing for seasonal renters, and credit card payment
                using the Stripe API. I also let the site administrator view all users
              signed up using a dashboard calendar. Yielded more than $60K in business.</p>
              <div className="icon-container">
                <div className="icon-tile">
                  <span><img src="ror.png" style={icons}/></span>
                  <p>Ruby on Rails 4.0</p>
                </div>
                <div className="icon-tile">
                  <span><img src="nginx.png" style={icons}/></span>
                  <p>NGINX</p>
                </div>
                <div className="icon-tile">
                  <span><img src="postgresql.png" style={icons}/></span>
                  <p>PostgreSQL</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default Project1;
