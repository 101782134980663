import React, { Component } from 'react';
import { BrowserRouter as Router, Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';

import Nav from './components/Nav'
import AboutMe from './components/AboutMe'
import Projects from './components/Projects'
import Photography from './components/Photography'
import Contact from './components/Contact'

import './App.css';


class App extends Component {
  render() {
    return (
      <Router>
      <div className="App">
        <header className="App-header">
          <title>Hemant Torsekar</title>
              <Nav/>
            </header>
            <div>
              <Switch>
                <Route path="/aboutme" component={AboutMe} />
                <Route path="/projects" component={Projects}/>
                <Route path="/photography" component={Photography}/>
                <Route path="/contact" component={Contact} />
              </Switch>
            </div>
        </div>
    </Router>
    );
  }
}

export default App;
