import React, { Component } from 'react';
import '../components/Contact.css';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';


const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      message: ''
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state })
      })
        .then(() => alert("Success!"))
        .catch(error => alert(error));
    };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });


  render() {

    return (
      <div className="mod-2 container">
        <div className="row">
            <div className="wrapper">
              <div className="container">
                <div className="row">
                  <div className="contact-container">
                      <h2>Hemant Torsekar</h2>
                      <h2><a href="mailto:hvt@me.com"> h-v-t[at]me-dot-com</a></h2>
                      <h2>647-907-7089</h2>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      )
    }
  }

export default Contact;
