import React, { Component } from 'react';
import '../components/Projects.css';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';

const icons = {
  height:'50px',
  width: '50px',
  display:'inline-block'
}

const iconStyle = {
  height:'50px',
  display:'inline-block'
}

class Project3 extends Component {
  render() {
    return (
      <div className="container">
        <div className="card">
          <h2><a href="http://www.vvuzz.com" target="_blank">VVUZZ.com</a></h2>
          <img src="vvuzz.png" />
        </div>
        <div className="description3">
          <p>A project where I was a freelance developer working for an agency.
            The site was intended to create a presence for a YouTube influencer.
            All styling and components were built by me using a KSS Stylesheet.
            This site made using Wordpress.
          </p>
          <div className="icon-container">
            <div className="icon-tile">
              <span><img src="wordpress.jpg" style={icons}/></span>
              <p>Wordpress</p>
            </div>
            <div className="icon-tile">
              <span><img src="web.png" style={iconStyle}/></span>
              <p>HTML5, CSS3, JS</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Project3;
