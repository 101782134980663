import React, { Component } from 'react';
import '../components/Projects.css';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';

const icons = {
  height:'50px',
  width: '50px',
  display:'inline-block'
}

class Project2 extends Component {
  render() {
    return (
    <div className="container">
      <div className="card">
        <h2>EmojiPop Connect</h2>
            <img src="emojipop1.png" alt="emojipop1" className="emojipop"/>
            <img src="emojipop2.png" alt="emojipop2" className="emojipop"/>
            <img src="emojipop3.png" alt="emojipop3" className="emojipop"/>
            <img src="emojipop4.png" alt="emojipop4" className="emojipop"/>
            <img src="emojipop5.png" alt="emojipop4" className="emojipop"/>
          <div className="description">
            <p>A game that challenges your conceptions of emoji as a language.
              Make decisions quickly and force your interpretations to be accurate
              as possible. Gives players a puzzle to decipher within 30 seconds.
              Finish the game by getting all the gems you can collect.
            </p>
            <div className="icon-container">
              <div className="icon-tile">
                <span><img src="swift.png" style={icons}/></span>
                <p>Swift 4</p>
              </div>
              <div className="icon-tile">
                <span><img src="cocoapods.png" style={icons}/></span>
                <p>Cocoaopds</p>
              </div>
              <div className="icon-tile">
                <span><img src="firebase.png" style={icons}/></span>
                <p>Firebase</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Project2;
